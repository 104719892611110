type ValueOf<T> = T[keyof T];

/** 登陆类型 */
export const LOGIN_TYPE = {
  NORMAL: {
    id: 'normal',
    cname: '账户密码登录',
  },
  IAM: {
    id: 'iam',
    cname: 'IAM 用户登录',
  },
} as const;

export type LOGIN_TYPE_ENUM = ValueOf<typeof LOGIN_TYPE>['id'];

/** 验证码发送类型 */
export const V_CODE_SEND = {
  /** 注册 */
  REGISTER: {
    value: 1,
  },
  /** 更改密码 */
  UPDATE_PASSWORD: {
    value: 2,
  },
  /** 重置/忘记密码 */
  RESET_PASSWORD: {
    value: 3,
  },
  /** 官网中的联系我们 */
  ABOUT: {
    value: 4,
  },
  /** 企业认证 */
  COMPANY_AUTH: {
    value: 5,
  },
  /** 个人认证 */
  PERSONAL_AUTH: {
    value: 6,
  },
  /** 修改手机号发送 */
  EDIT_PHONE: {
    value: 7,
  },
  /** 申请测试账号 */
  TEST_CODE: {
    value: 8,
  },
} as const;
/** 验证码发送类型枚举 */
export type V_CODE_SEND_TYPE = ValueOf<typeof V_CODE_SEND>['value'];

/** Console 地址在缓存中的 key */
export const CONSOLE_URL = 'CONSOLE_URL';

/** 邀请注册的 key */
export const SHARE_CODE_SEARCH_KEY = 'share_code'; // 在 urlSearch 中
export const SHARE_CODE_STORE_KEY = 'SHARE_CODE'; // 在本地缓存中

/** cookie种到的域 */
export const DOMAIN =
  process.env.NODE_ENV === 'production' ? process.env.GATSBY_CONSOLE_DOMAIN : '';

export const enum INVITATION {
  /** 邀请码邀请 */
  CODE = 1,
  /** 用户分享链接邀请 */
  SHARE_CODE = 2,
}
