export const enum ENV {
  /** 测试环境 */
  TEST = 'test',
  /** 正式环境，最新的 xxyy.co */
  PROD = 'prod',
  /** 正式环境，旧官网 saturncloud.com.cn */
  WEBSITE = 'website',
}

/** 当前所处的域名环境 */
export const getEnv = () => {
  const host = window.location.host;
  // 旧官网
  if (host === 'saturncloud.com.cn') {
    return ENV.WEBSITE;
  } else if (host === 'xxyy.co') {
    // 新官网地址
    return ENV.PROD;
  } else {
    // 测试
    return ENV.TEST;
  }
};
